<template>
  <div class="ym-page">
    <!-- 顶部组件 -->
    <search-form :form="form" @search="search"></search-form>
    <!-- 列表 -->
    <table-list :datas="searchVal"></table-list>

  </div>
</template>

<script>
import SearchForm from './component/SearchForm.vue'
import TableList from './component/TableList.vue'
export default {
  components: {
    SearchForm,
    TableList
  },
  data() {
    return {
      // 表单
      form: {
        id: '', // 状态ID
        parentHash: '', // 最新修改记录
        statusVersion: '' // 状态版本
      },
      searchVal: {
        id: '', // 状态ID
        parentHash: '', // 最新修改记录
        statusVersion: '' // 状态版本
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    search(form) {
      // 重置表单
      this.form = form
      // 搜索列表
      let _form = JSON.parse(JSON.stringify(form))
      this.searchVal = _form
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep {
  .el-input__inner {
    // width: 180px !important;
  }
}
.head-title {
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  margin-right: 16px;
}
.left {
  margin-left: 16px;
}
</style>
